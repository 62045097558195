<script>

import {
  ArrowUpIcon,
  MonitorIcon,
  FeatherIcon,
  EyeIcon,
  UserCheckIcon,
  SmartphoneIcon,
  HeartIcon,
} from "vue-feather-icons";
import Navbar from "@/components/navbar1";
import Switcher from "@/components/switcher";
import Footer from "@/components/footer";
import Pricing from "@/components/pricing2";
import Testimonial from "@/components/testimonial";
import {Carousel, Slide} from "vue-carousel";

/**
 * Email-password-reset component
 */
export default {
  components: {
    MonitorIcon,
    FeatherIcon,
    EyeIcon,
    UserCheckIcon,
    SmartphoneIcon,
    HeartIcon,
  },

}
</script>

<template>


                        <!-- Features Start -->
                        <section class="section bg-light">
                          <div class="container">
                            <div class="row justify-content-center">
                              <div class="col-12 text-center">
                                <div class="section-title mb-4 pb-2">
                                  <h4 class="title mb-4">App Features</h4>
                                  <p class="text-muted para-desc mb-0 mx-auto">
                                    Start working with
                                    <span class="text-primary font-weight-bold">Landrick</span> that
                                    can provide everything you need to generate awareness, drive
                                    traffic, connect.
                                  </p>
                                </div>
                              </div>
                              <!--end col-->
                            </div>
                            <!--end row-->

                            <table style="box-sizing: border-box; width: 100%; border-radius: 6px; overflow: hidden; background-color: #fff; box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);">

                              <tbody>
                              <tr>
                                <td>
                                    <div class="media features pt-4 pb-4">

                                        <monitor-icon
                                            class="fea icon-ex-md text-primary"
                                        ></monitor-icon>

                                      <div class="media-body">
                                        <h4 class="title">Use On Any Device</h4>
                                        <p class="text-muted para mb-0">
                                          Composed in a pseudo-Latin language which more or less
                                          pseudo-Latin language corresponds.
                                        </p>
                                      </div>
                                    </div>
                                  </td>
                                  <!--end col-->

                                  <td>
                                    <div class="media features pt-4 pb-4">

                                        <feather-icon
                                            class="fea icon-ex-md text-primary"
                                        ></feather-icon>

                                      <div class="media-body">
                                        <h4 class="title">Feather Icons</h4>
                                        <p class="text-muted para mb-0">
                                          Composed in a pseudo-Latin language which more or less
                                          pseudo-Latin language corresponds.
                                        </p>
                                      </div>
                                    </div>
                                  </td>
                              </tr>
                                  <!--end col-->

                              <tr>
                                <td>
                                    <div class="media features pt-4 pb-4">

                                        <eye-icon class="fea icon-ex-md text-primary"></eye-icon>

                                      <div class="media-body">
                                        <h4 class="title">Retina Ready</h4>
                                        <p class="text-muted para mb-0">
                                          Composed in a pseudo-Latin language which more or less
                                          pseudo-Latin language corresponds.
                                        </p>
                                      </div>
                                    </div>
                                  </td>
                                  <!--end col-->

                                  <td>
                                    <div class="media features pt-4 pb-4">

                                        <user-check-icon
                                            class="fea icon-ex-md text-primary"
                                        ></user-check-icon>

                                      <div class="media-body">
                                        <h4 class="title">W3c Valid Code</h4>
                                        <p class="text-muted para mb-0">
                                          Composed in a pseudo-Latin language which more or less
                                          pseudo-Latin language corresponds.
                                        </p>
                                      </div>
                                    </div>
                                  </td>
                              </tr>
                                  <!--end col-->

                              <tr>
                                <td>
                                    <div class="media features pt-4 pb-4">

                                        <smartphone-icon
                                            class="fea icon-ex-md text-primary"
                                        ></smartphone-icon>

                                      <div class="media-body">
                                        <h4 class="title">Fully Responsive</h4>
                                        <p class="text-muted para mb-0">
                                          Composed in a pseudo-Latin language which more or less
                                          pseudo-Latin language corresponds.
                                        </p>
                                      </div>
                                    </div>
                                  </td>
                                  <!--end col-->

                                  <td>
                                    <div class="media features pt-4 pb-4">

                                        <heart-icon
                                            class="fea icon-ex-md text-primary"
                                        ></heart-icon>

                                      <div class="media-body">
                                        <h4 class="title">Browser Compatibility</h4>
                                        <p class="text-muted para mb-0">
                                          Composed in a pseudo-Latin language which more or less
                                          pseudo-Latin language corresponds.
                                        </p>
                                      </div>
                                    </div>
                                  </td>
                              </tr>
                                  <!--end col-->
                              </tbody>
                                <!--end row-->
                            </table>
                          </div>
                        </section>
</template>

<style>
body {
    font-family: Nunito, sans-serif;
    font-size: 15px;
    font-weight: 400;
    overflow: visible;
}
</style>
